import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: ':lang', // Dil parametresini üst rota olarak tanımlıyoruz
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' }, // Varsayılan rota
      { path: 'home', loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule), data: { breadcrumb: 'Home' } },
      { path: 'about', loadChildren: () => import('./components/pages/about/about.module').then(m => m.AboutModule), data: { breadcrumb: 'About Us' } },
      { path: 'services', loadChildren: () => import('./components/pages/services/services.module').then(m => m.ServicesModule), data: { breadcrumb: 'Services' } },
      { path: 'service-details', loadChildren: () => import('./components/pages/service-details/service-details.module').then(m => m.ServiceDetailsModule), data: { breadcrumb: 'Service Details' } },
      { path: 'gallery', loadChildren: () => import('./components/pages/gallery/gallery.module').then(m => m.GalleryModule), data: { breadcrumb: 'Gallery' } },
      { path: 'why-turkey', loadChildren: () => import('./components/pages/why-turkey/why-turkey.module').then(m => m.WhyTurkeyModule), data: { breadcrumb: 'Why Turkey' } },
      { path: 'prof-dr-akin-ugras', loadChildren: () => import('./components/pages/akin-ugras/akin-ugras.module').then(m => m.AkinUgrasModule), data: { breadcrumb: 'Prof. Dr. Akin Ugras' } },
      { path: 'op-dr-sevda-ugras', loadChildren: () => import('./components/pages/sevda-ugras/sevda-ugras.module').then(m => m.SevdaUgrasModule), data: { breadcrumb: 'Op. Dr. Sevda Ugras' } },
      { path: 'basinda', loadChildren: () => import('./components/pages/basinda/basinda.module').then(m => m.BasindaModule), data: { breadcrumb: 'Press' } },
      { path: 'team', loadChildren: () => import('./components/pages/team/team.module').then(m => m.TeamModule), data: { breadcrumb: 'Our Team' } },
      { path: 'contact', loadChildren: () => import('./components/pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: 'Contact' } },
      { path: 'blog-standard', loadChildren: () => import('./components/pages/blog-standard/blog-standard.module').then(m => m.BlogStandardModule), data: { breadcrumb: 'Blog' } },
      { path: 'blog-details/:id', loadChildren: () => import('./components/pages/blog-details/blog-details.module').then(m => m.BlogDetailsModule), data: { breadcrumb: 'Blog Details' } },
      { path: 'blog/cat/:catId', loadChildren: () => import('./components/pages/blog-grid/blog-grid.module').then(m => m.BlogGridModule), data: { breadcrumb: 'Blog Category' } },
      { path: 'blog/tag/:tagId', loadChildren: () => import('./components/pages/blog-grid/blog-grid.module').then(m => m.BlogGridModule), data: { breadcrumb: 'Blog Tag' } },
      { path: 'blog/author/:authorId', loadChildren: () => import('./components/pages/blog-grid/blog-grid.module').then(m => m.BlogGridModule), data: { breadcrumb: 'Blog Author' } },
      { path: 'error', loadChildren: () => import('./components/pages/error/error.module').then(m => m.ErrorModule), data: { breadcrumb: 'Error' } }, // Error Module eklendi
      { path: '**', redirectTo: 'home' } // Geçersiz rotalar için yönlendirme
    ]
  },
  { path: '**', redirectTo: 'tr/home' } // Varsayılan dil
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: true })], // Ayarlar eklendi
  exports: [RouterModule]
})
export class AppRoutingModule {}
